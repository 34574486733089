import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import * as styles from "../styles/home.module.css"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ContactLink from "../components/ContactLink/ContactLink"
import SEO from "../components/SEO/SEO"

export default function Home({ data }) {
  return (
    <>
      <SEO title="Anuwataravis" />
      <Layout>
        <section className={styles.header}>
          <div>
            <div className={styles.logo}>
              <svg
                width="100%"
                viewBox="0 0 111 95"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="8"
                  y="7"
                  width="103"
                  height="88"
                  rx="12"
                  fill="#D8D8D8"
                />
                <rect
                  x="5"
                  y="5"
                  width="103"
                  height="87"
                  rx="12"
                  fill="#C4C4C4"
                />
                <rect width="104" height="88" rx="12" fill="#DBDBDB" />
                <rect
                  x="7.79297"
                  y="7"
                  width="17.7517"
                  height="72.8562"
                  rx="2"
                  fill="#3B3B3C"
                />
                <rect
                  x="89.4504"
                  y="7"
                  width="15.1784"
                  height="71.0067"
                  transform="rotate(90 89.4504 7)"
                  fill="#3B3B3C"
                />
                <rect
                  x="78.7998"
                  y="7"
                  width="17.7517"
                  height="72.8562"
                  fill="#3B3B3C"
                />
                <path d="M101 25L101 40L2 40L2 25L101 25Z" fill="#DBDBDB" />
              </svg>
            </div>
            <h2>Junior Full-Stack Developer</h2>
            <h3>
              Passionate about <br /> Software Engineering <br />
              and Data Technology.
            </h3>
            <ContactLink />
          </div>
        </section>
      </Layout>
    </>
  )
}

export const query = graphql`
  query Banner {
    banner: file(relativePath: { eq: "banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
      }
    }
  }
`
