import React from "react"
import * as styles from "./contact.module.css"
function ContactLink() {
  return (
    <div className={styles.contactLink}>
      <ul>
        <li className={styles.listLink}>
          <a href="https://github.com/anuwatavis" target="__blank">
            Github{" "}
            <svg
              width="15"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 16H2V2H9V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C17.1 18 18 17.1 18 16V9H16V16ZM11 0V2H14.59L6 12.5L16 3.41V7H18V0H11Z"
                fill="#3B3B3C"
              />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/anuwataravis/" target="__blank">
            LinkedIn{" "}
            <svg
              width="15"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 16H2V2H9V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C17.1 18 18 17.1 18 16V9H16V16ZM11 0V2H14.59L6 12.5L16 3.41V7H18V0H11Z"
                fill="#3B3B3C"
              />
            </svg>
          </a>
        </li>
        <li>
          <a href="https://medium.com/@anuwataravis" target="__blank">
            Medium{" "}
            <svg
              width="15"
              height="15"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 16H2V2H9V0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16C0 16.5304 0.210714 17.0391 0.585786 17.4142C0.960859 17.7893 1.46957 18 2 18H16C17.1 18 18 17.1 18 16V9H16V16ZM11 0V2H14.59L6 12.5L16 3.41V7H18V0H11Z"
                fill="#3B3B3C"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  )
}

export default ContactLink
