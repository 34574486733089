import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import socialBanner from "../../../static/web-preview.jpg"
function SEO({ description, lang, meta, title }) {
  return (
    <Helmet>
      <title>anuwataravis</title>
      <meta name="title" content="anuwataravis" />
      <meta
        name="description"
        content="anuwataravis.dev share my blog , my note, my art, etc."
      />
      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://anuwataravis.dev/" />
      <meta property="og:title" content="anuwataravis" />
      <meta property="og:image" content={socialBanner} />
    </Helmet>
  )
}

export default SEO
